var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "legal-documents" },
    [
      _c("div", { staticClass: "doc-message" }, [
        _vm._v(
          "\n    You will find all mutual legal documents (generated by Grupa) here. These\n    are standard documents generated to suit your product’s pecularities.\n  "
        ),
      ]),
      _c(
        "div",
        { staticClass: "doc-list-container" },
        _vm._l(_vm.grupaDoc, function (document) {
          return _c(
            "DocumentCard",
            _vm._b(
              { key: document.id },
              "DocumentCard",
              { document: document, viewDocument: _vm.viewDocument },
              false
            )
          )
        }),
        1
      ),
      _c("GrupaNDA", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.pdfText,
            expression: "pdfText",
          },
        ],
        ref: "ndaContainer",
      }),
      _c(
        "GrupaSLA",
        _vm._b(
          {
            ref: "grupa_sla",
            staticStyle: { overflow: "hidden", height: "0" },
            attrs: { id: "grupa-sla", devUser: true },
          },
          "GrupaSLA",
          { teamDetails: _vm.teamDetails, projectDoc: _vm.projectDoc },
          false
        )
      ),
      _c("PlatformAgreement", {
        ref: "platformAgreement",
        staticStyle: { overflow: "hidden", height: "0" },
        attrs: { id: "platformAgreement" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
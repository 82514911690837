<template>
  <div class="legal-documents">
    <div class="doc-message">
      You will find all mutual legal documents (generated by Grupa) here. These
      are standard documents generated to suit your product’s pecularities.
    </div>
    <div class="doc-list-container">
      <DocumentCard
        v-for="document in grupaDoc"
        :key="document.id"
        v-bind="{ document, viewDocument }"
      />
    </div>
    <GrupaNDA ref="ndaContainer" v-show="pdfText" />
    <GrupaSLA
      ref="grupa_sla"
      id="grupa-sla"
      style="overflow: hidden; height: 0"
      v-bind="{ teamDetails, projectDoc }"
      v-bind:devUser="true"
    />
    <PlatformAgreement
      ref="platformAgreement"
      id="platformAgreement"
      style="overflow: hidden; height: 0"
    />
  </div>
</template>

<script>
import * as jsPDF from "jspdf/dist/jspdf.debug";
import html2canvas from "html2canvas";
window.html2canvas = html2canvas;
import html2pdf from "html2pdf.js";
import { mapActions, mapState } from "vuex";
import GrupaNDA from "@/components/general/grupaNDA";
import GrupaSLA from "@/components/general/grupaSLA";
import PlatformAgreement from "@/components/general/PlatformAgreement";
import DocumentCard from "./documentCard";
export default {
  props: {},
  components: {
    DocumentCard,
    GrupaSLA,
    GrupaNDA,
    PlatformAgreement,
  },
  data: () => ({
    pdfText: false,
    isAgreement: false,
    grupaDoc: [
      {
        id: 1,
        name: "Service Level Agreement",
        data: "28th April 2020",
        short: "SLA",
        document_type_id: 5,
        file_name: "Service Level Agreement.pdf",
        link: "/pdf/boom_sla.pdf",
      },
      {
        id: 2,
        name: "Non-Disclosure Agreement",
        data: "28th April 2020",
        short: "NDA",
        document_type_id: 5,
        file_name: "Non-Disclosure Agreement.pdf",
        link: "/pdf/boom_nda.pdf",
      },
      {
        id: 3,
        name: "Platform Agreement",
        data: "28th April 2020",
        short: "PlatformAgreement",
        document_type_id: 5,
        file_name: "Platform Agreement.pdf",
      },
      {
        id: 4,
        name: "Grupa Disciplinary Action",
        data: "28th April 2020",
        short: "GDA",
        document_type_id: 5,
        file_name: "Grupa Disciplinary Action.pdf",
        link: "/pdf/Disciplinary_action_company_policy.pdf",
      },
      {
        id: 5,
        name: "Grupa Design Thinking Schedule",
        data: "28th April 2020",
        short: "DTS",
        document_type_id: 5,
        file_name: "Grupa Design Thinking Schedule.pdf",
        link: "/pdf/Grupa_design_sprint.pdf",
      },
    ],
  }),

  created() {},
  methods: {
    viewDocument(document) {
      let { short: docName } = document;
      if (docName == "NDA") {
        // let pdf = "/pdf/boom_nda.pdf";
        // window.open(pdf);
        let pdf = new jsPDF();
        const elementHTML = this.$refs.ndaContainer.$refs.ndaWrapper;
        var specialElementHandlers = {
          "#elementH": function (element, renderer) {
            return true;
          },
        };
        pdf.setFontSize(18);
        pdf.fromHTML(elementHTML, 15, 15, {
          width: 170,
          elementHandlers: specialElementHandlers,
        });
        pdf.output("dataurlnewwindow");
      } else if (docName == "SLA") {
        // let pdf = "/pdf/boom_sla.pdf";
        // window.open(pdf);
        const element = this.$refs.grupa_sla.$refs.slaWrapper;
        let fileName = this.projectDoc.project.project_name;
        const settings = this.pdfSettings(fileName);
        const doc = html2pdf().set(settings).from(element);
        doc.output("datauristring").then((pdfAsString) => {
          let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
          var fileURL = URL.createObjectURL(output);
          window.open(fileURL);
        });
      } else if (docName == "DTS") {
        let pdf = "/pdf/Grupa_design_sprint.pdf";
        window.open(pdf);
      } else if (docName == "GDA") {
        let pdf = "/pdf/Disciplinary_action_company_policy.pdf";
        window.open(pdf);
      } else if (docName == "PlatformAgreement") {
        const elementHTML =
          this.$refs.platformAgreement.$refs.agreementContainer;

        const settings = this.pdfSettings("platformAgreement");
        const doc = html2pdf().set(settings).from(elementHTML);
        doc.output("datauristring").then((pdfAsString) => {
          let output = this.base64ToBlob(pdfAsString.split("base64,")[1]);
          var fileURL = URL.createObjectURL(output);
          window.open(fileURL);
        });

        // let pdf = new jsPDF();
        // var specialElementHandlers = {
        //   "#elementH": function (element, renderer) {
        //     return true;
        //   },
        // };
        // pdf.setFontSize(18);
        // pdf.fromHTML(elementHTML, 15, 15, {
        //   width: 170,
        //   elementHandlers: specialElementHandlers,
        // });
        // pdf.output("dataurlnewwindow");
      }
    },
    base64ToBlob: function (base64) {
      const binaryString = window.atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; ++i) {
        bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes], { type: "application/pdf" });
    },
    pdfSettings(fileName) {
      let today = new Date();
      const date = `${today.getFullYear()}-${
        today.getMonth() + 1
      }-${today.getDate()}`;
      const time = `${today.getHours()}:${today.getMinutes()}:${today.getSeconds()}`;
      const dateTime = `${date}_${time}`;
      return {
        margin: [15, 15],
        filename: `SLA_${fileName}_${dateTime}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 192, scale: 2, letterRendering: true },
        jsPDF: { unit: "pt", format: "letter", orientation: "portrait" },
        pageBreak: { mode: ["avoid-all", "css", "legacy"] },
      };
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc", "teamInfo"]),
    teamDetails() {
      return this.teamInfo.team;
    },
    projectDoc() {
      return this.selectedProjectDoc;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.doc-message {
  width: 534px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.doc-list-container {
  display: flex;
  margin-top: 32px;
  flex-wrap: wrap;
}
</style>

<template>
  <div
    class="document-card"
    @mouseover="
      hoverDoc = true;
      currentDoc = document.id;
    "
    @mouseleave="
      hoverDoc = false;
      currentDoc = '';
    "
  >
    <div class="doc-icon-container">
      <div>
        <img
          :src="`/img/doc-icons/${documentTypeIcon(
            document.file_name.split('.')[1]
          )}.svg`"
          alt=""
        />
      </div>
    </div>
    <div class="doc-details">
      <div class="flex-wrap">
        <div class="document-name">
          {{ truncate(document.file_name.split(".")[0], 24) }} .{{
            document.file_name.split(".")[1]
          }}
        </div>
        <div class="icon-container">
          <a
            v-if="hoverDoc && currentDoc == document.id"
            title="Download"
            style="text-decoration: none; color: #000"
            :href="document.link"
          >
            <img src="/img/doc-icons/download-icon.svg" alt="" />
          </a>
        </div>
      </div>
      <div class="flex-wrap" style="margin-top: 8px">
        <div class="uploaded-user">
          Uploaded by
          {{
            document.sender_id
              ? getUploaderUser(document.sender_id).name
              : "Grupa"
          }}
        </div>
        <div class="icon-container">
          <img
            @click="viewDocument(document)"
            v-if="hoverDoc && currentDoc == document.id"
            src="/img/doc-icons/expand-icon.svg"
            alt=""
          />
        </div>
      </div>
      <div class="document-category">
        {{
          document.document_type_id
            ? uploadedLocation(document.document_type_id)
            : ""
        }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import truncate from "@/mixins/truncate";
export default {
  mixins: [truncate],
  props: {
    document: {
      type: Object,
      required: true,
    },
    viewDocument: {
      type: Function,
      required: true,
    },
  },
  components: {},

  data: () => ({
    currentDoc: "",
    hoverDoc: false,
  }),

  created() {},
  methods: {
    getUploaderUser(userId) {
      let userObj = this.members.find((member) => member.id == userId);
      if (userObj) {
        return userObj;
      } else {
        return { name: "Project Client" };
      }
    },
    uploadedLocation(docTypeId) {
      switch (docTypeId) {
        case 1:
          return `${this.projectName}/doc/task`;
        case 2:
          return `${this.projectName}/doc/sprint`;
        case 3:
          return `${this.projectName}/doc/feature`;
        case 4:
          return `${this.projectName}/doc/project`;
        case 5:
          return `${this.projectName}/doc/legal`;
      }
    },
    documentTypeIcon(type) {
      switch (type) {
        case "pdf":
          return `pdf`;
        case "doc":
        case "docx":
          return `doc`;
        case "mp3":
          return `mp3`;
        case "mp4":
          return `video`;
        case "zip":
          return `zip`;
        default:
          return `pdf`;
      }
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProject"]),
    ...mapState("devDashboard", ["teamInfo", "team"]),
    members() {
      return this.teamInfo.team.members;
    },
    project() {
      return this.selectedProject;
    },
    projectName() {
      return this.selectedProject.project_name;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.document-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  width: 282px;
  margin: 0px 16px 16px 0px;
  cursor: pointer;
}
.doc-icon-container {
  height: 159px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.doc-details {
  text-align: left;
}
.flex-wrap {
  display: flex;
  margin-top: 16px;
  justify-content: space-between;
}
.document-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.uploaded-user {
  font-size: 12px;
  line-height: 140%;
  color: #1b1e22;
}
.document-category {
  font-size: 12px;
  line-height: 140%;
  color: #c8cbd0;
  margin-top: 8px;
}
.icon-container {
  height: 16px;
}
</style>

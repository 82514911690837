var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.productDoc == null
        ? _c("Loader")
        : _c("div", { staticClass: "document-wrapper" }, [
            _c("div", { staticClass: "doc-top-navigation" }, [
              _c("div", { staticClass: "document-toggle" }, [
                _c(
                  "div",
                  {
                    staticClass: "document-btn",
                    class: { " active-btn": _vm.activeTab == "legal" },
                    on: {
                      click: function ($event) {
                        _vm.activeTab = "legal"
                      },
                    },
                  },
                  [_vm._v("\n          Legal\n        ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "document-btn",
                    class: { " active-btn": _vm.activeTab == "product" },
                    on: {
                      click: function ($event) {
                        _vm.activeTab = "product"
                      },
                    },
                  },
                  [_vm._v("\n          Product Docs\n        ")]
                ),
              ]),
              _c("div", { staticClass: "search-and-filter" }, [
                _c("div", { staticClass: "search-textbox" }, [
                  _c("div", { staticClass: "div-search-btn" }, [
                    _c("img", {
                      attrs: { src: "/img/doc-icons/search-icon.svg", alt: "" },
                    }),
                  ]),
                  _c("input", {
                    staticClass: "search-text-box",
                    attrs: { type: "text", placeholder: "Search documents" },
                  }),
                ]),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "document-container" },
              [
                _vm.activeTab == "legal" ? _c("LegalDocuments") : _vm._e(),
                _vm.activeTab == "product"
                  ? _c(
                      "ProductDocuments",
                      _vm._b(
                        {},
                        "ProductDocuments",
                        { productDoc: _vm.productDoc },
                        false
                      )
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
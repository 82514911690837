var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "document-card",
      on: {
        mouseover: function ($event) {
          _vm.hoverDoc = true
          _vm.currentDoc = _vm.document.id
        },
        mouseleave: function ($event) {
          _vm.hoverDoc = false
          _vm.currentDoc = ""
        },
      },
    },
    [
      _c("div", { staticClass: "doc-icon-container" }, [
        _c("div", [
          _c("img", {
            attrs: {
              src:
                "/img/doc-icons/" +
                _vm.documentTypeIcon(_vm.document.file_name.split(".")[1]) +
                ".svg",
              alt: "",
            },
          }),
        ]),
      ]),
      _c("div", { staticClass: "doc-details" }, [
        _c("div", { staticClass: "flex-wrap" }, [
          _c("div", { staticClass: "document-name" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm.truncate(_vm.document.file_name.split(".")[0], 24)) +
                " ." +
                _vm._s(_vm.document.file_name.split(".")[1]) +
                "\n      "
            ),
          ]),
          _c("div", { staticClass: "icon-container" }, [
            _vm.hoverDoc && _vm.currentDoc == _vm.document.id
              ? _c(
                  "a",
                  {
                    staticStyle: { "text-decoration": "none", color: "#000" },
                    attrs: { title: "Download", href: _vm.document.link },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: "/img/doc-icons/download-icon.svg",
                        alt: "",
                      },
                    }),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "flex-wrap", staticStyle: { "margin-top": "8px" } },
          [
            _c("div", { staticClass: "uploaded-user" }, [
              _vm._v(
                "\n        Uploaded by\n        " +
                  _vm._s(
                    _vm.document.sender_id
                      ? _vm.getUploaderUser(_vm.document.sender_id).name
                      : "Grupa"
                  ) +
                  "\n      "
              ),
            ]),
            _c("div", { staticClass: "icon-container" }, [
              _vm.hoverDoc && _vm.currentDoc == _vm.document.id
                ? _c("img", {
                    attrs: { src: "/img/doc-icons/expand-icon.svg", alt: "" },
                    on: {
                      click: function ($event) {
                        return _vm.viewDocument(_vm.document)
                      },
                    },
                  })
                : _vm._e(),
            ]),
          ]
        ),
        _c("div", { staticClass: "document-category" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.document.document_type_id
                  ? _vm.uploadedLocation(_vm.document.document_type_id)
                  : ""
              ) +
              "\n    "
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }